import {
  Box,
  BoxProps,
  Flex,
  ThemeTypings,
  useStyleConfig,
  useToken,
} from "@chakra-ui/react";

import AlarmIcon from "./AlarmIcon";

import { darken } from "polished";

const EaiCard = (
  props: BoxProps & {
    variant?: ThemeTypings["components"]["EaiCard"]["variants"];
    renderHeader?: () => JSX.Element;
    showIcon?: boolean;
  }
) => {
  const { variant, renderHeader, children, showIcon, ...rest } = props ?? {};
  const styles = useStyleConfig("EaiCard", { variant });

  return (
    <Box __css={styles} {...rest}>
      {renderHeader && (
        <EaiCardHeader variant={variant} showIcon={showIcon}>
          {renderHeader()}
        </EaiCardHeader>
      )}
      {children}
    </Box>
  );
};

export default EaiCard;

export const EaiCardHeader = (
  props: BoxProps & {
    variant?: ThemeTypings["components"]["EaiCardHeader"]["variants"];
    showIcon?: boolean;
    // renderHeaderIcon?: () => React.Component;
  }
) => {
  const headerStyles = useStyleConfig("EaiCardHeader", {
    variant: props.variant,
  });

  const cardStyles = useStyleConfig("EaiCard", {
    variant: props.variant,
  });

  // @ts-ignore
  const [bg] = useToken("colors", [cardStyles?.bg]);
  const { showIcon, ...rest } = props;

  const Icon = AlarmIcon;

  const derivedBg = bg?.startsWith("#") ? darken(0.05, bg) : bg;

  const headerBg = headerStyles?.bg ?? derivedBg;
  return (
    <Flex
      flex="1"
      alignItems="center"
      fontWeight="bold"
      {...rest}
      as={Flex}
      __css={headerStyles}
      background={headerBg}

      // borderBottom="1px solid"
      // borderColor="gray.300"
    >
      {showIcon && <Icon boxSize={6} mr="4" />}
      {props.children}
    </Flex>
  );
};
