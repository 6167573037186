import React, { useState, Fragment } from "react";
import {
  Box,
  Flex,
  Divider,
  Text,
  Button,
  Spacer,
  Center,
  Input,
  FormControl,
  FormLabel,
  Spinner,
  Alert,
  useBreakpointValue,
  AlertIcon,
  Link as ChakraLink,
  ButtonGroup,
} from "@chakra-ui/react";
import { useRouter } from "next/router";
import { gql, useMutation, useQuery } from "@apollo/client";
import { FormattedMessage } from "react-intl";
import Link from "next/link";
import logo from "public/images/allshares_logo_small.png";
import {
  GetLoginConfigQuery,
  GetGenericSignicatLoginUrlsQuery,
  LoginMutation,
  LoginWebContentQuery,
  SignicatLoginUrl,
  SsoType,
} from "apollo/generatedTypes";
import getApolloClient from "apollo/client";
import { GetServerSidePropsContext } from "next";
import Image from "next/image";
import LockIcon from "components/LockIcon";

import SignicatDropdown from "./components/SignicatDropdown";
import Helpdesk from "components/Helpdesk/Helpdesk";
import { getQueryState, QueryState } from "components/queryUtil";
import WebContentContainer from "components/WebContentContainer";
import { ApolloError } from "@apollo/client/errors";
import redirectUnrecognizedCompany from "../redirectUnrecognizedCompany";
import { setCookie } from "cookies-next";
import MobileIdLogin from "./MobileIdLogin";
import AlarmIcon from "components/AlarmIcon";
import EaiCard from "components/EaiCard";

export const LOGIN = gql`
  mutation login($username: String!, $password: String!, $ip: String) {
    login(username: $username, password: $password, ip: $ip) {
      baseUrl
      id
    }
  }
`;

export const LOGIN_WEB_CONTENT = gql`
  query loginWebContent($language: String!) {
    getLoginWebContent(language: $language)
  }
`;

const GENERIC_SIGNICAT_LOGIN_URLS = gql`
  query getGenericSignicatLoginUrls {
    getGenericSignicatLoginUrls {
      method
      url
    }
  }
`;

const apolloClient = getApolloClient();

enum LoginMethod {
  MobileId = "MobileId",
  Signicat = "Signicat",
  Password = "Password",
}

const Login = (
  props: GetLoginConfigQuery["getCompany"] & {
    refreshInitialData: () => void;
    locale: string;
    ip?: string;
  }
) => {
  const { ssoType, ssoLoginLinkUrl, signicatLoginUrls, id, ip } = props ?? {};
  const isCompanyLogin = !!id;

  const router = useRouter();

  const [handleLogin, { loading, error }] = useMutation<LoginMutation>(LOGIN, {
    onCompleted: ({ login }) => {
      props.refreshInitialData();
      const { baseUrl } = login ?? {};
      router.push(`/${encodeURIComponent(baseUrl as string)}/postlogin`);
    },

    onError: (error) => {
      console.error("LOGIN ERROR?", error);
    },
    client: apolloClient,
  });

  const [credentials, handleCredentials] = useState({
    username: "",
    password: "",
  });

  const narrowScreen = useBreakpointValue({
    base: true,
    sm: false,
  });

  const payload = useQuery<LoginWebContentQuery>(LOGIN_WEB_CONTENT, {
    client: apolloClient,
    variables: { language: props.locale },
    skip: !props.locale,
  });

  const queryState = getQueryState(payload);
  const { data } = payload;
  const { getLoginWebContent: webContent } = data ?? {};

  setCookie("companyBaseUrl", router?.query?.company, {
    maxAge: 60 * 60,
  });

  const availableLoginMethods = [
    ...(signicatLoginUrls && signicatLoginUrls?.length > 0
      ? [LoginMethod.Signicat]
      : []),
    ...(props?.loginWithMobileIdEnabled ? [LoginMethod.MobileId] : []),
    ...(props?.loginWithUsernameEnabled ? [LoginMethod.Password] : []),
  ];

  const [selectedLoginMethod, handleLoginMethod] = useState<LoginMethod | null>(
    availableLoginMethods?.length ? availableLoginMethods[0] : null
  );

  const genericSignicatLoginUrlsPayload =
    useQuery<GetGenericSignicatLoginUrlsQuery>(GENERIC_SIGNICAT_LOGIN_URLS, {
      client: apolloClient,
      skip: isCompanyLogin,
    });

  const genericSignicatUrls =
    genericSignicatLoginUrlsPayload.data?.getGenericSignicatLoginUrls;

  const genericSignicatUrlsQueryState = getQueryState(
    genericSignicatLoginUrlsPayload
  );

  return (
    <Fragment>
      <Center
        pr={[0, 0, 24]}
        pl={[0, 0, 24]}
        pt="24"
        flexDirection="column"
        gap="8"
        maxWidth="1148px"
        margin="auto"
        zIndex="0"
        position="relative"
      >
        {queryState === QueryState.ERRORED && (
          <Box width="100%">
            <Alert status="error" justifyContent="center" borderRadius="14">
              <AlertIcon />
              <Text fontWeight="bold">
                <FormattedMessage
                  id="login.failedToFetchAnnouncement"
                  defaultMessage="Failed to fetch announcement"
                />
              </Text>
            </Alert>
          </Box>
        )}

        {queryState === QueryState.FINISHED && webContent && (
          <EaiCard width="100%" overflow="hidden" variant="primary">
            <Alert status="success" justifyContent="center" gap="4">
              <AlarmIcon color="orange.400" boxSize="6" />
              <WebContentContainer content={webContent} />
            </Alert>
          </EaiCard>
        )}

        <EaiCard
          display="flex"
          width="100%"
          flexDirection={["column", "column", "row"]}
          variant="primary"
          background="white"
        >
          <Flex flexDirection="column" flex={1} p="8">
            <Flex gap="4" flexDirection="column">
              {ssoType && (
                <Fragment>
                  {narrowScreen && (
                    <Text
                      fontWeight="bold"
                      as="h4"
                      textTransform="uppercase"
                      fontSize="12px"
                    >
                      <FormattedMessage
                        id="login.loginWithCompanyCredentials"
                        defaultMessage="Login with company credentials"
                      />
                    </Text>
                  )}

                  <ChakraLink
                    href={ssoLoginLinkUrl as string}
                    _hover={{ textDecoration: "none" }}
                  >
                    <Button
                      justifyContent="space-between"
                      fontSize="12"
                      leftIcon={<LockIcon height="18px" />}
                      height="auto"
                      whiteSpace="break-spaces"
                      size="sm"
                      py="2"
                    >
                      {narrowScreen ? (
                        <Text>
                          <FormattedMessage
                            id="login.ssoLogin"
                            defaultMessage="SSO Login"
                          />
                        </Text>
                      ) : (
                        <Fragment>
                          {ssoType === SsoType.Azure && (
                            <FormattedMessage
                              id="login.azureSSO"
                              defaultMessage="Azure SSO"
                            />
                          )}
                          {ssoType === SsoType.Google && (
                            <FormattedMessage
                              id="login.googleSSO"
                              defaultMessage="Google SSO"
                            />
                          )}
                          {ssoType === SsoType.Okta && (
                            <Fragment>
                              <FormattedMessage
                                id="login.OktaSSO"
                                defaultMessage="Okta SSO"
                              />
                            </Fragment>
                          )}
                        </Fragment>
                      )}

                      <Text>
                        {/* Intentionally blank text component to make SSO text above center */}
                      </Text>
                    </Button>
                  </ChakraLink>

                  <Flex alignItems="center" gap="4">
                    <Divider borderColor="gray.400" />
                    <Text>
                      <FormattedMessage id="common.or" defaultMessage="or" />
                    </Text>
                    <Divider borderColor="gray.400" />
                  </Flex>
                </Fragment>
              )}
              <FormattedMessage
                id="login.chooseMethod"
                defaultMessage="Choose a login method"
              />

              <ButtonGroup
                isAttached={true}
                size="xs"
                borderRadius="8"
                width="100%"
                borderBottomRadius="0"
                borderColor="gray.400"
              >
                {signicatLoginUrls && signicatLoginUrls?.length > 0 && (
                  <Button
                    data-cy="selectLoginWithBankingCodes"
                    size="xs"
                    height="auto"
                    flex="1"
                    py="1"
                    whiteSpace="break-spaces"
                    overflow="hidden"
                    overflowWrap="anywhere"
                    onClick={() => {
                      handleLoginMethod(LoginMethod.Signicat);
                    }}
                    variant={
                      selectedLoginMethod === LoginMethod.Signicat
                        ? "solidBlue"
                        : "outlineBlue"
                    }
                    borderBottomRadius="0"
                    borderColor="gray.400"
                  >
                    <FormattedMessage
                      id="login.bankingCodes"
                      defaultMessage="Banking codes"
                    />
                  </Button>
                )}

                {props.loginWithMobileIdEnabled && (
                  <Button
                    data-cy="selectLoginWithMobileId"
                    size="xs"
                    height="auto"
                    variant={
                      selectedLoginMethod === LoginMethod.MobileId
                        ? "solidBlue"
                        : "outlineBlue"
                    }
                    flex="1"
                    py="1"
                    whiteSpace="break-spaces"
                    overflow="hidden"
                    overflowWrap="anywhere"
                    onClick={() => {
                      handleLoginMethod(LoginMethod.MobileId);
                    }}
                    borderBottomRadius="0"
                    borderColor="gray.400"
                  >
                    <FormattedMessage
                      id="login.mobileId"
                      defaultMessage="Incentive.Online ID"
                    />
                  </Button>
                )}

                {props.loginWithUsernameEnabled && (
                  <Button
                    data-cy="selectLoginWithUsernameAndPassword"
                    size="xs"
                    height="auto"
                    flex="1"
                    py="1"
                    whiteSpace="break-spaces"
                    overflow="hidden"
                    overflowWrap="anywhere"
                    onClick={() => {
                      handleLoginMethod(LoginMethod.Password);
                    }}
                    variant={
                      selectedLoginMethod === LoginMethod.Password
                        ? "solidBlue"
                        : "outlineBlue"
                    }
                    borderBottomRadius="0"
                    borderColor="gray.400"
                  >
                    <FormattedMessage
                      id="login.usernameAndPassword"
                      defaultMessage="Username & password"
                    />
                  </Button>
                )}
              </ButtonGroup>
            </Flex>

            {selectedLoginMethod === LoginMethod.Signicat && (
              <Box boxShadow="lg">
                <SignicatDropdown
                  signicatLoginUrls={signicatLoginUrls as SignicatLoginUrl[]}
                  boxShadow="none"
                />
              </Box>
            )}

            {selectedLoginMethod === LoginMethod.MobileId && (
              <Box boxShadow="lg">
                <MobileIdLogin
                  ip={ip}
                  refreshInitialData={props.refreshInitialData}
                  forgotUserNameEnabled={!!props.forgotUserNameEnabled}
                />
              </Box>
            )}

            {selectedLoginMethod === LoginMethod.Password && (
              <Flex
                flexDirection="column"
                minHeight="280px"
                height="100%"
                boxShadow="lg"
              >
                {props.loginWithUsernameEnabled && (
                  <Flex flex={1} p="4">
                    <Box width="100%">
                      <Text
                        mt="4"
                        fontWeight="bold"
                        as="h4"
                        textTransform="uppercase"
                        fontSize="12px"
                      >
                        <FormattedMessage
                          id="login.test"
                          defaultMessage="Login with credentials"
                        />
                      </Text>
                      <Spacer minHeight="6" />
                      <form
                        name="login"
                        onSubmit={(e) => {
                          e.preventDefault();
                          const { username, password } = credentials ?? {};
                          if (username && password) {
                            if (!loading)
                              handleLogin({
                                variables: { username, password, ip },
                              });
                          }
                        }}
                      >
                        <FormControl>
                          <FormLabel
                            textTransform="uppercase"
                            fontSize="12px"
                            color="primary"
                            id="username_label"
                            htmlFor="username_input"
                          >
                            <FormattedMessage
                              id="login.userName"
                              defaultMessage="Username"
                            />
                          </FormLabel>

                          <Input
                            autoCapitalize="none"
                            data-cy="loginUsername"
                            id="username_input"
                            size="sm"
                            name="username"
                            type="text"
                            onChange={(e) =>
                              handleCredentials({
                                ...credentials,
                                username: e.target.value,
                              })
                            }
                          />
                        </FormControl>
                        <Spacer minHeight="4" />
                        <FormControl>
                          <FormLabel
                            textTransform="uppercase"
                            fontSize="12px"
                            color="primary"
                            id="password_label"
                            htmlFor="password_input"
                          >
                            <FormattedMessage
                              id="login.password"
                              defaultMessage="Password"
                            />
                          </FormLabel>
                          <Input
                            data-cy="loginPassword"
                            id="password_input"
                            size="sm"
                            name="password"
                            type="password"
                            onChange={(e) =>
                              handleCredentials({
                                ...credentials,
                                password: e.target.value,
                              })
                            }
                          />
                        </FormControl>
                        <Flex mt="4" flexDirection="column">
                          <Button
                            name="loginButton"
                            variant="solidBlue"
                            data-cy="loginButton"
                            type="submit"
                            textTransform="uppercase"
                            fontSize="small"
                            size="sm"
                            fontWeight="500"
                            minWidth="100px"
                            height="auto"
                            whiteSpace="break-spaces"
                            py="2"
                          >
                            <FormattedMessage
                              id="login.login"
                              defaultMessage="Log in"
                            />
                            {loading && <Spinner ml="3" size="sm" />}
                          </Button>

                          {error && (
                            <Alert color="black" status="error" mt="2">
                              <Text fontWeight="bold">{error?.message}</Text>
                            </Alert>
                          )}
                        </Flex>
                      </form>
                    </Box>
                  </Flex>
                )}
                <Flex alignSelf="flex-end" flexDirection="column" width="100%">
                  <Flex justifyContent="flex-end" alignItems="center" p="4">
                    <Text fontSize="small" color="gray.700">
                      <Link href={"/forgotPassword"}>
                        <FormattedMessage
                          id="login.forgotPassword"
                          defaultMessage="Forgot your password?"
                        />
                      </Link>
                    </Text>
                  </Flex>
                </Flex>

                {genericSignicatUrlsQueryState === QueryState.ERRORED && (
                  <Alert color="black" status="error" mt="2">
                    <Text fontWeight="bold">
                      <FormattedMessage
                        id="login.failedToFetchGenericSignicatUrls"
                        defaultMessage="Failed to fetch generic signicat urls"
                      />
                    </Text>
                  </Alert>
                )}

                {genericSignicatUrls && genericSignicatUrls.length > 0 && (
                  <Box width="100%" minWidth="340px">
                    <Divider mt="4" />
                    <Text
                      pl="4"
                      pt="8"
                      fontWeight="bold"
                      as="h4"
                      textTransform="uppercase"
                      fontSize="12px"
                    >
                      <FormattedMessage
                        id="login.loginWithBankingCodes"
                        defaultMessage="Login with banking codes"
                      />
                    </Text>
                    <Spacer minHeight="6" />
                    <SignicatDropdown
                      signicatLoginUrls={
                        genericSignicatUrls as SignicatLoginUrl[]
                      }
                      boxShadow="none"
                      genericLogin={true}
                    />
                  </Box>
                )}
              </Flex>
            )}
          </Flex>
          <Helpdesk
            companyId={id}
            apolloClient={apolloClient}
            locale={props.locale}
          />
        </EaiCard>
      </Center>
      <Flex
        position={["relative", "relative", "fixed"]}
        // background="rgba(0, 0, 0, 0.2)"
        mt={[4, 4, 0]}
        bottom="0"
        right="0"
        textDecoration="underline"
        color="white"
        padding="2"
        paddingLeft="4"
        paddingRight="4"
        borderTopLeftRadius={[0, 0, 4]}
        justifyContent="center"
      >
        <Image
          src={logo}
          style={{ objectFit: "contain" }}
          alt=""
          priority={true}
        />
      </Flex>
    </Fragment>
  );
};

Login.defaultProps = {
  noAuthNeeded: true,
};

const GET_LOGIN_CONFIG = gql`
  query getLoginConfig($companyBaseUrl: String) {
    getCompany(companyBaseUrl: $companyBaseUrl) {
      id
      signicatLoginUrls {
        method
        url
      }
      ssoLoginLinkUrl
      ssoType
      loginWithUsernameEnabled
      loginWithMobileIdEnabled
      forgotUserNameEnabled
    }
  }
`;
export async function getServerSideProps(params: GetServerSidePropsContext) {
  const ip = params?.req?.headers["x-real-ip"] ?? null;

  if (params?.query.company) {
    try {
      const { data } = await getApolloClient().query<GetLoginConfigQuery>({
        query: GET_LOGIN_CONFIG,
        variables: {
          companyBaseUrl: encodeURIComponent(params?.query.company as string),
        },
      });

      const { getCompany } = data ?? {};

      return {
        props: {
          id: getCompany?.id ?? null,
          signicatLoginUrls: getCompany?.signicatLoginUrls ?? [],
          ssoLoginLinkUrl: getCompany?.ssoLoginLinkUrl ?? "",
          ssoType: getCompany?.ssoType ?? null,
          ip,
          loginWithUsernameEnabled: !!getCompany?.loginWithUsernameEnabled,
          loginWithMobileIdEnabled: !!getCompany?.loginWithMobileIdEnabled,
          forgotUserNameEnabled: !!getCompany?.forgotUserNameEnabled,
        },
      };
    } catch (err) {
      const redirectConfig = redirectUnrecognizedCompany(err as ApolloError);
      if (redirectConfig) {
        return redirectConfig;
      }
      return {
        props: {
          ip,
        },
      };
    }
  }
  return {
    props: {
      ip,
    },
  };
}

export default Login;
